<template>
  <CCard v-if="!task">
    <CCardHeader>
      <h2>Task ID {{ id }} Not Found</h2>
    </CCardHeader>
  </CCard>
  <CCard v-else>
    <CCardHeader>
      <h2>Override: {{ task.title }}</h2>
    </CCardHeader>
    <CCardBody></CCardBody>
    <CCardFooter>
      <CButtonToolbar>
        <CButton color="primary">Save</CButton>
        <CButton color="secondary" @click="$router.go(-1)">Cancel</CButton>
      </CButtonToolbar>
    </CCardFooter>
  </CCard>
</template>
<script>
import { entityLoadingMixin } from '@/mixins';
import TASK_LIST from '@/../static/data/taskList.json';

export default {
  name: 'EditTask',
  mixins: [entityLoadingMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dispatchPath: 'tasks/fetchTaskDetails',
    };
  },
  computed: {
    task() {
      return this.fetchedData;
    },
  },
};
</script>
